body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: black;
}

a:visited {
  color: black;
}
